import './App.css';
import KeyboardModule from './keyboard';


function App() {
  return (
    <div className="App">
      <KeyboardModule/>
    </div>
  );
}

export default App;
