
import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

import Modal from 'react-modal';


export default function KeyboardModule() {
    const keyRef = useRef(null);
    const mountRef = useRef(null);

    const modelRef = useRef(null);

    const [typeText, setTypeText] = useState('');
    const [index, setIndex] = useState(0);
    const [listIndex, setListIndex] = useState(0);
    const customStyles = {
        overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.4)"
          },
        closeButton: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
        },
    };
    

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
    }

    function closeModal() {
        setIsOpen(false);
    }


    
    useEffect(() => {
        
        const textList = ["Hello", "Sain uu", "Welcome", "Tavtai moril", "Thanks for visiting", "Zochilsond bayarlalaa", "Good luck", "Tand amjilt husey", "Greetings", "Udriin mend"]
        const keyMapping = {
            'esc': 21, 'a': 22, 'z': 23, 'tab': 24, 'q': 25, 'capslock': 26, 'lshift': 27,
            ' ': 28, 'lctrl': 29, 'window': 30, 'lalt': 31, 'page': 32, 'fn': 33,
            'ralt': 34, 'rctrl': 35, '\\': 36, '|': 36, 'backspace': 37, 'enter': 38,
            'rshift': 39, '1': 40, '2': 41, '3': 42, '4': 43, '5': 44, '6': 45,
            '7': 46, '8': 47, '9': 48, '0': 49, '-': 50, '+': 51, '=': 51, 's': 52,
            'd': 53, 'f': 54, 'g': 55, 'h': 56, 'j': 57, 'k': 58, 'l': 59, ';': 60,
            '\'': 61, 'x': 62, 'c': 63, 'v': 64, 'b': 65, 'n': 66, 'm': 67, ',': 68,
            '<': 68, '.': 69, '>': 69, '/': 70, '?': 70, 'w': 71, 'e': 72, 'r': 73,
            't': 74, 'y': 75, 'u': 76, 'i': 77, 'o': 78, 'p': 79, '[': 80, ']': 81
        };
       
        if (index < textList[listIndex].length) {
            const currentChar = textList[listIndex][index].toLowerCase();
            const keyIndex = keyMapping[currentChar]; 
            const timeoutId = setTimeout(() => {
                setTypeText(typeText + textList[listIndex][index]);
                setIndex(index + 1);
                const key = keyRef.current[keyIndex];
                if (key) {
                    key.position.y -= 0.0035;
                    setTimeout(() => {
                        key.position.y += 0.0035;
                    }, 150);
                }
            }, 300);
            return () => clearTimeout(timeoutId);
        } else {
            const timeoutId = setTimeout(() => {
                setTypeText('');
                setIndex(0);
                setListIndex((listIndex + 1) % textList.length);
            }, 1500);

            return () => clearTimeout(timeoutId);
        }
    }, [index, listIndex, typeText]);


    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 1;
        const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
        renderer.gammaOutput = true;
        renderer.gammaFactor = 1;
        renderer.setClearColor( 0x282c34, 1 );
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
        scene.add(directionalLight);
        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);
        const currentMount  =  mountRef.current;
        const loader = new GLTFLoader();
        loader.load('static/scene.gltf', (gltf) => {
            modelRef.current = gltf.scene;

            if(window.innerWidth > 800){
                modelRef.current.scale.set(5,5, 5);
            }
            else if(window.innerWidth > 500){
                modelRef.current.scale.set(3.5,3.5, 3.5);
            }

            else if(window.innerWidth > 380){
                modelRef.current.scale.set(3,3, 3);
            }
            else {
                modelRef.current.scale.set(2.5,2.5, 2.5);
            }


            modelRef.current.rotation.y = -0.0;
            modelRef.current.rotation.x = 0.8;
            keyRef.current = gltf.scene.children[0].children[0].children[0].children;
            scene.add(gltf.scene);
        }, undefined, (error) => {
            console.error('An error happened loading the GLTF model:', error);
        });
        let clock = new THREE.Clock();
        const animate = () => {
            requestAnimationFrame(animate);
            let time = clock.getElapsedTime();
            if (modelRef.current) {
                modelRef.current.rotation.x = 0.6 + 0.1 * Math.sin(time * 0.5);
                modelRef.current.rotation.y = 0.1 * Math.sin(time * 0.5);
            }
            renderer.render(scene, camera);
        };
        animate();
        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            renderer.dispose();
            if (currentMount) {
                currentMount.removeChild(renderer.domElement);
            }
        };
    }, []);


    return (
        <div className='relative'>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                className="modal-content"
            >

                <div className='flex justify-between'>
                    <div className='text-3xl mt-2 font-bold font-mono'>About me</div>
                    <button onClick={closeModal} type="button" class="bg-[#282c34] rounded-md p-2 inline-flex items-center justify-center text-white hover:text-[#00ff00] hover:bg-[#282c34] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#00ff00]">
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className='mt-6 text-2xl font-mono'>Greetings, my name is <span className='font-bold'>Battuvshin.</span></div>


                <div className='mt-3 text-lg'>
                    I am a software developer and IT manager based in Ulaanbaatar, Mongolia that has a "knack" for all things related to technology. 
                    My expertise encompasses all processes involved in the development of websites, systems, and mobile applications. In addition to my background in software development, I have some experience in leading both software and IT teams.
                    If you have any enquiries or questions you can connect with me through my <a className='font-bold' href='tel:99221194' style={{ color: '#00ff00' }}>phone</a> and <a href='mailto:battuvshin.bay@gmail.com' className='font-bold' style={{ color: '#00ff00' }}>email</a>.

                </div>

            </Modal>


            <div className='static' ref={mountRef} style={{ width: '100vw', height: '100vh' }}></div>
            
            <div className='top-0 start-0 absolute text-2xl font-bold font-mono'>
                <div className='text-[#fff] mt-5 ms-5'>Battuvshin<span className='font-serif'>&lt;DEV&gt;</span></div>
            </div>


            <div className='bottom-0 end-0 absolute text-xl font-bold font-mono'>
                <div className='text-[#fff] mb-5 me-5 text-end'>
                    Contact me at<br/>
                    <a href='tel:99221194' style={{ color: '#00ff00' }}>99221194</a>
                    <br/>
                    <a href='mailto:battuvshin.bay@gmail.com' style={{ color: '#00ff00' }}>battuvshin.bay@gmail.com</a>
                </div>
            </div>
            
            <div className='top-0 end-0 absolute text-2xl font-bold font-mono'>
                <button onClick={openModal} className='text-[#00ff00] mt-5 me-5'>
                    About
                </button>
            </div>

            <div className='absolute inset-0 top-[30%] max-h-[2em] items-center justify-center text-xl font-bold font-mono '>
                <div className='text-[#00ff00]'>{typeText}<span className="blink-caret"></span></div>
            </div>
        </div>
    )
}

//About must have content
//responseve keyboard modal
// "keyboard" (https://skfb.ly/6UFI8) by 45P3R4 is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).